import React from 'react'
import { graphql } from 'gatsby'

import { Fade, Layout } from '../../components'
import close from '../../assets/close.svg'

class Awards extends React.Component {

  title = 'Awards'

  render() {
    return (
      <Layout page={this}>
        <div className="contact contact--awards">

          <div className="contact__bg" style={{backgroundImage: `url(${this.props.data.page.awardsCover.url}?auto=format&fit=min&w=1200)`}}>
          </div>

          <div className="contact__awards wrapper">
            <div className="lg:relative">
              <div className="contact__inner mx-auto md:w-6/8 lg:w-6/10 xl:w-4/10">
                <div className="relative">
                  <h1 className="font-head text-24 tracking-bit pb-10 mt-40 mb-20">Awards</h1>
                  <Fade to={`/contact/`} className="contact__close-btn"><img src={close} alt="close"/></Fade>
                </div>

                <div className="contact__wysiwyg" dangerouslySetInnerHTML={{__html: this.props.data.page.awards}}></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Awards

export const query = graphql`
  query AwardsQuery {
    page: datoCmsContactpage {
      awards
      awardsCover {
        url
      }
    }
  }
`
